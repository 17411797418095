@import '../../../../scss/theme-bootstrap';

.live-chat__button-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  .live-chat__button {
    &.button {
      color: $color-white;
    }
    &.button--secondary {
      color: $color-dark-gray;
      &:hover {
        color: $color-white;
      }
    }
    &.button--transparent {
      color: $color-dark-gray;
      &:hover {
        color: $color-dark-gray;
      }
    }
    &.button--transparent--black {
      &.button--transparent {
        color: $color-dark-gray;
        &:hover {
          color: $color-white;
        }
      }
    }
    &.button--transparent--white {
      color: $color-dark-gray;
      &:hover {
        color: $color-white;
      }
    }
  }
}
